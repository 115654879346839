import React from "react";
import Header from "../../components_en/Header";
import Footer from "../../components_en/Footer";
import TitleSection from "../../components_en/TitleSection";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';

import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import UV_IconImg from '../../graphics/icons/UV_icon.gif';
import UVATubeImg from '../../graphics/products/UVA_tubes.jpg';
import ProductSlider from '../../components_en/ProductSlider'

function T89W () {
    return(
        <>
            <Header/>
            <TitleSection h1={'T8 9W'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div className='productCategories-productPageContainer'>
                    <div className='productCategories-descriptionAndParameters'>
                        <div className='productCategories-description'>
                            <ul className="productCategories-secondSectionList">
                            <li>Designed and manufactured in EU</li>
                            <li>The best quality diodes from Korea</li>
                            <li>Durability of 50,000 hours</li>
                            <li>High effectiveness in catching flies confirmed by tests</li>
                            <li>100% of construction and production in Poland</li>
                            <li>Homogeneous light, no spottines (optics from Germany)</li>
                            <li>Attractive price</li>
                            <li>Warranty 2 years</li>
                            </ul>
                        </div>
                        <ProductSlider productImages={[UVATubeImg]}/>
                    </div>
                    <div className='productCategories-productParameters'>
                            <div className='productCategories-productParametersBox'>
                                <p>Dimensions:</p>
                                <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                                <p>Diameter 25.8mm</p>
                                <p>L: 450mm</p>                                          
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                                <p>Voltage: 230V</p>
                                <p>Power: 9W</p>
                                <p>Current: 50mA</p>
                                <p>IP20 (IEC 60529) </p>
                                <p>UVA: 3.7W</p>
                                <p>UVA LED: 370.5 nm</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <p>Operating temperature: -40C to 45C</p>
                                <p>Storage temperature: -45C to 60C</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={UV_IconImg} alt='icon'/>
                                <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                            </div>
                        </div>
                        </div>
        </div>
        <Footer/>
        </>
    );
}

export default T89W;