import React from "react";
import Header from "../../components_en/Header";
import Footer from "../../components_en/Footer";
import TitleSection from "../../components_en/TitleSection";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';

import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import UV_IconImg from '../../graphics/icons/UV_icon.gif';
import InsectTrapImg from '../../graphics/products/InsectTrap.jpg';
import ProductSlider from '../../components_en/ProductSlider'



function InsectTrap () {
    return(
        <>
            <Header/>
            <TitleSection h1={'Insect Trap'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div className='productCategories-productPageContainer'>
                    <span className='productCategories-productDescription'>
                        Insect Trap features a simple design, entirely made of aluminum and stainless steel, allowing the device to be placed in high humidity environments such as kitchens and restaurants.
                        The device is equipped with a 9-watt LED lamp. The lamp is entirely free of lead and mercury, which can be found in devices equipped with traditional UVA fluorescent tubes.
                        The used LED lamp is made of plastic, eliminating the possibility of breakage (explosion) during operation and accidental contact, for example, with food.
                        Additionally, the device includes a pheromone-based adhesive that attracts insects.
                        There is the possibility of customizing the perforation pattern and finishing color, from raw brushed aluminum to finishes with high scratch-resistant paints.
                    </span>
                    <div className='productCategories-descriptionAndParameters'>
                        <div className='productCategories-description'>
                            <ul className="productCategories-secondSectionList">
                            <li>Insect trap with UVA & pheromones</li>
                            <li>3m Power Cord with Unischuko  plug</li>
                            <li>Designed and manufactured in EU</li>
                            <li>The best quality diodes from Korea</li>
                            <li>Durability of 50.000 hours</li>
                            <li>High effectiveness in catching flies confirmed by tests</li>
                            <li>100% of construction and production in Poland</li>
                            <li>Homogeneous light, no spottiness (optics from Germany)</li>
                            <li>Attractive price</li>
                            <li>Warranty 2 years</li>
                            </ul>
                        </div>
                        <ProductSlider productImages={[InsectTrapImg]}/>
                    </div>
                    <div className='productCategories-productParameters'>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                                <p>Dimensions:</p>
                                <p>L: 515mm</p>            
                                <p>H: 255mm</p>
                                <p>W: 75mm</p>                            
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                                <p>Voltage: 230V</p>
                                <p>Frequency: 50-60Hz</p>
                                <p>Power: 10W</p>
                                <p>Operation temperature: -40ºC / +45ºC</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={UV_IconImg} alt='icon'/>
                                <p>UVA: 3.7W</p>
                                <p>UVA LED: 370.5 nm</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <p>CE Compatibility</p>
                                <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                            </div>
                        </div>
                        </div>
        </div>
        <Footer/>
        </>
    );
}

export default InsectTrap;