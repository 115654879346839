import React from "react";
import Header from "../../components_en/Header";
import Footer from "../../components_en/Footer";
import TitleSection from "../../components_en/TitleSection";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';

import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import UV_IconImg from '../../graphics/icons/UV_icon.gif';
import UVATubeImg from '../../graphics/products/UVA_tubes.jpg';
import wingsFlippering from '../../graphics/wings_flippering.jpg';
import ProductSlider from '../../components_en/ProductSlider'


function T89WFlyWingsSimulation () {
    return(
        <>
            <Header/>
            <TitleSection h1={'T8-9W FlyWings Simulation'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div className='productCategories-productPageContainer'>
            <div style={{width: '80%'}} className='productCategories-description'>
                        This is a unique version of the T8 LED lamp developed on the basis of scientific research. 
The UV light stream is not constant and is modulated in such a way that it is more attractive to flies compared to a standard lamp with the same rated power.
The microcontroller that controls the operation of the lamp constantly changes light parameters in accordance with an established algorithm based on research.
Light changes are invisible to the human eye, but attractive to insects, which have vision with a much shorter reaction time and a higher image processing frequency.

                        </div>
                    <div className='productCategories-descriptionAndParameters'>
                        <div className='productCategories-description'>
                            <ul className="productCategories-secondSectionList">
                            <li>Designed and manufactured in EU</li>
                            <li>The best quality diodes from Korea</li>
                            <li>Durability of 50,000 hours</li>
                            <li>High effectiveness in catching flies confirmed by tests</li>
                            <li style={{color: "red"}}>Highest effectiveness - catching flies more effectively by simulating the flapping motion of flies' wings</li>
                            <li>100% of construction and production in Poland</li>
                            <li>Homogeneous light, no spottines (optics from Germany)</li>
                            <li>Attractive price</li>
                            <li>Warranty 2 years</li>
                            </ul>
                        </div>
                        <ProductSlider productImages={[UVATubeImg]}/>
                        <div className="productCategories-productImageWithDescription">
                            <h4>Wings Flippering</h4>
                            <img style={{maxWidth: "500px", height: "auto", marginTop: "10px", marginBottom: "20px"}} src={wingsFlippering} className="productCategories-productImage" />
                            <p>Picture source: ’How flies are flirting on the fly’,
                            February 2017BMC Biology 15(2)Follow journal;
                            DOI: 10.1186/s12915-016-0342-6</p>
                        </div>
                    </div>
                    <div className='productCategories-productParameters'>
                            <div className='productCategories-productParametersBox'>
                                <p>Dimensions:</p>
                                <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                                <p>Diameter 25.8mm L: 450 mm</p>
                                <p>L: 450mm</p>                                          
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                                <p>Voltage: 230V</p>
                                <p>Power: 9W</p>
                                <p>Current: 50mA</p>
                                <p>IP20 (IEC 60529) </p>
                                <p>UVA: 3.7W</p>
                                <p>UVA LED: 370.5 nm</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <p>Operating temperature: -40C to 45C</p>
                                <p>Storage temperature: -45C to 65C</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={UV_IconImg} alt='icon'/>
                                <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                            </div>
                        </div>
                        </div>
        </div>
        <Footer/>
        </>
    );
}

export default T89WFlyWingsSimulation;