import React from "react";
import Header from "../../components_pl/Header";
import Footer from "../../components_pl/Footer";
import TitleSection from "../../components_en/TitleSection";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';

import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import UV_IconImg from '../../graphics/icons/UV_icon.gif';
import InsectTrapImg from '../../graphics/products/InsectTrap.jpg';
import ProductSlider from '../../components_en/ProductSlider'



function InsectTrap () {
    return(
        <>
            <Header/>
            <TitleSection h1={'Insect Trap'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div className='productCategories-productPageContainer'>
                    <span className='productCategories-productDescription'>
                    Pułapka na owady charakteryzuje się prostą konstrukcją, w całości wykonaną z aluminium i stali nierdzewnej, co pozwala na umieszczenie urządzenia w środowiskach o dużej wilgotności, takich jak kuchnie i restauracje. Urządzenie wyposażone jest w lampę LED o mocy 9 W. Lampa jest całkowicie wolna od ołowiu i rtęci, które można znaleźć w urządzeniach wyposażonych w tradycyjne świetlówki UVA. Zastosowana lampa LED wykonana została z tworzywa sztucznego, co eliminuje możliwość stłuczenia (wybuchu) podczas pracy oraz przypadkowego kontaktu np. z żywnością. Dodatkowo w urządzeniu znajduje się klej na bazie feromonów, który przyciąga owady. Istnieje możliwość dostosowania wzoru perforacji i koloru wykończenia, od surowego szczotkowanego aluminium po wykończenia farbami o wysokiej odporności na zarysowania.
                    </span>
                    <div className='productCategories-descriptionAndParameters'>
                        <div className='productCategories-description'>
                            <ul className="productCategories-secondSectionList">
                            <li>pułapka na owady z promieniowaniem UVA i feromonami</li>
                            <li>Przewód zasilający o długości 3m z wtyczką Unischuko</li>
                            <li>Zaprojektowane i wyprodukowane w UE</li>
                            <li>Diody najwyższej jakości pochodzące z Korei</li>
                            <li>Trwałość 50 000 godzin</li>
                            <li>Wysoka skuteczność w łapaniu much potwierdzona badaniami</li>
                            <li>100% konstrukcji i produkcji w Polsce</li>
                            <li>Jednorodne światło, brak plam (optyka z Niemiec)</li>
                            <li>Atrakcyjna cena</li>
                            <li>Gwarancja 2 lata</li>
                            </ul>
                        </div>
                        <ProductSlider productImages={[InsectTrapImg]}/>
                    </div>
                    <div className='productCategories-productParameters'>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                                <p>Wymiary:</p>
                                <p>L: 515mm</p>            
                                <p>H: 255mm</p>
                                <p>W: 75mm</p>                            
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                                <p>Napięcie znamionowe: 230V</p>
                                <p>Częstotliwość: 50-60Hz</p>
                                <p>Moc: 10W</p>
                                <p>Temperatura pracy: -40ºC - 45ºC</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={UV_IconImg} alt='icon'/>
                                <p>UVA: 3.7W</p>
                                <p>UVA LED: 370.5 nm</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <p>Zgodność CE</p>
                                <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                            </div>
                        </div>
                        </div>
        </div>
        <Footer/>
        </>
    );
}

export default InsectTrap;