import {useState} from 'react';

import Header from "../components_en/Header";
import Footer from "../components_en/Footer";
import TitleSection from "../components_en/TitleSection";
import Line from "../components_en/Line";
import logoDark from '../graphics/logo-next-lighting-dark.png';
import '../styles/App.css';
import '../styles/Contact.css';

import footerIconMail from '../graphics/icons/icon-mail-dark.png';
import footerIconPhone from '../graphics/icons/icon-phone-dark.png';
import footerIconLinkedin from '../graphics/icons/icon-linkedin-dark.png';
import footerIconFacebook from '../graphics/icons/icon-facebook-dark.png';
import footerIconYoutube from '../graphics/icons/icon-youtube-dark.png';

function Contact () {

        const [formError, setFormError] = useState("");
        const [isSuccess, setSuccess] = useState(false);
        const validateForm = () => {
            let errorMsg = "";
            const inputs = document.getElementsByClassName("contact-FormInput");
            for (let i = 0; i < inputs.length; i++)
            {
                if (inputs[i].getAttribute("isrequired") == "1")
                {
                    if (inputs[i].value.length == 0)
                        errorMsg += " " + inputs[i].getAttribute("placeholder");
                }
            }
            if (document.getElementById("contact-formTextArea").value == 0)
                errorMsg += " Message*";
            if (document.getElementById("contact-privatyPolicyCheckbox").checked != true)
                errorMsg += " Privacy Policy*";
            return errorMsg;
        }
        const sendForm = (formJSONText) => {
            console.log(formJSONText);
            fetch("/send.php", 
            {
                method: 'POST', 
                headers: 
                {
                    'Content-Type': 'application/json'
                },
                body: formJSONText
            })
            .then(response => response.json())
            .then(responseJSON => {
                if (responseJSON.errorCode==0)
                {
                    const subjectInput = document.getElementById("contact-subjectInput");
                    const firstNameInput  = document.getElementById("contact-firstnameInput");
                    const lastNameInput  = document.getElementById("contact-lastnameInput");
                    const companyInput  = document.getElementById("contact-companyInput");
                    const emailInput  = document.getElementById("contact-emailInput");
                    const phoneInput  = document.getElementById("contact-phoneInput");
                    const messageInput  = document.getElementById("contact-formTextArea");
                    subjectInput.value  = "";
                    firstNameInput.value = "";
                    lastNameInput.value = "";
                    companyInput.value = "";
                    emailInput.value = "";
                    phoneInput.value = "";
                    messageInput.value = "";
                    setSuccess(true);
                    setFormError("");
                }
                else
                {
                    setFormError("Server error. Try again later.");
                }
            }).catch(error => {
                setFormError("Network error. Please try again.");
            });
            
        }
        const form = () => {
            const subjectInput = document.getElementById("contact-subjectInput");
            const firstNameInput  = document.getElementById("contact-firstnameInput");
            const lastNameInput  = document.getElementById("contact-lastnameInput");
            const companyInput  = document.getElementById("contact-companyInput");
            const emailInput  = document.getElementById("contact-emailInput");
            const phoneInput  = document.getElementById("contact-phoneInput");
            const messageInput  = document.getElementById("contact-formTextArea");
            
            let subject = subjectInput.value;
            let firstName = firstNameInput.value;
            let lastName = lastNameInput.value;
            let company = companyInput.value;
            let email = emailInput.value;
            let phone = phoneInput.value;
            let message = messageInput.value;

            let errors = validateForm();
            if (errors.length == 0)
            {
                let formJSON = JSON.stringify({
                    "subject": subject,
                    "firstName": firstName,
                    "lastName": lastName,
                    "company": company,
                    "email": email,
                    "phoneNum": phone,
                    "message": message
                });
                sendForm(formJSON);
            }
            else
                setFormError("You must complete field/fields: "+ errors+".");
            if (isSuccess)
                setSuccess(false);
        }
        return(
            <>
                <Header />
                <TitleSection h1={'CONTACT US'} h2={''} height='280px'/>
                <div id='contact-secondSection'>
                    <div className='contact-centeredBox'>
                        <img id='company-secondSectionLogo' src={logoDark} alt="Next Lighting" title="Next Lighting" />
                        <h4>We are looking forward to develop the best solution for you...</h4>
                    </div>
                </div>
                <div id='contact-thirdSection'>
                    <div id='contact-thirdSectionCentered'>
                        <div className='contact-contactBox'>
                            <h4>How to find us in Poland?</h4>
                            <Line color1='#dfc08d' color2='#69dbe1' color3='white' lineWidth='200px' lineHeight='2px' />
                            <div className='contactGroupBox'>
                                <p>NEXT Lighting Ltd</p>
                                <p>Chrzanowskiego 23 str.</p>
                                <p>87-100 Toruń</p>
                                <p>Poland</p>
                            </div>
                            <div className='contact-GroupBox'>
                                <a className={'contact-Link'} href='mailto:sales@nextlighting.eu'>
                                <img className='contact-Icon' src={footerIconMail} alt='mail'/>
                                sales@nextlighting.eu
                                </a>
                                <a className={'contact-Link'} href='tel:+48789036056'>
                                    <img className='contact-Icon' src={footerIconPhone} alt='+48 780 147 737'/>
                                    +48 798 123 022
                                </a>
                                <a className={'contact-Link'} href='https://www.linkedin.com/company/73946341/admin/notifications/all/' rel='noreferrer' target="_blank">
                                    <img className='contact-Icon' src={footerIconLinkedin} alt='LinkedIn'/>
                                    LinkedIn
                                </a>
                                <a className={'contact-Link'} href='https://www.facebook.com/Next-Lighting-Ltd-103384115441575' rel='noreferrer' target="_blank">
                                    <img className='contact-Icon' src={footerIconFacebook} alt='Facebook'/>
                                    Facebook
                                </a>
                                <a className={'contact-Link'} href='https://www.youtube.com/channel/UCZveMiUuRaB8O0ZPEVSZEYg' rel='noreferrer' target="_blank">
                                    <img className='contact-Icon' src={footerIconYoutube} alt='Youtube'/>
                                    Youtube
                                </a>
                            </div>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2398.7404169725573!2d18.69050127634151!3d53.043002472202815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ccd03e29dfc59%3A0xcddeeef63edbac0c!2sNEXT%20Lighting%20sp.%20z%20o.o.!5e0!3m2!1spl!2spl!4v1696442876299!5m2!1spl!2spl" style={{width: "100%", height: "300px", borderWidth: "0"}} llowfullscreen="" loading="lazy"></iframe>
                        </div>
                        <div className='contact-contactBox'>
                            <h4>Contact form</h4>
                            <Line color1='#dfc08d' color2='#69dbe1' color3='white' lineWidth='200px' lineHeight='2px' />
                            <div className='contact-groupBox'>
                                <input id="contact-subjectInput" placeholder="Subject*" type='text' className="contact-FormInput" isrequired="1"/>
                                <input id="contact-firstnameInput" placeholder="Firstname*" type='text' className="contact-FormInput" isrequired="1"/>
                                <input id="contact-lastnameInput" placeholder="Lastname*" type='text' className="contact-FormInput" isrequired="1"/>
                                <input id="contact-companyInput" placeholder="Company" type='text' className="contact-FormInput" isrequired="0"/>
                                <input id="contact-emailInput" placeholder="Email address*" type='email' className="contact-FormInput" isrequired="1"/>
                                <input id="contact-phoneInput" placeholder="Phone number" type='tel' className="contact-FormInput" isrequired="0"/>
                                <textarea placeholder="Message*" id="contact-formTextArea" isrequired="1"></textarea>       
                                
                                <div className="contact-privacyPolicyBox">
                                    <input id="contact-privatyPolicyCheckbox" type="checkbox" />
                                    <span>I consent to the processing of my personal data in accordance with the
                                    <a href='/privacy-policy'> privacy policy</a>
                                    </span>
                                </div>
                                {
                                    (formError.length>0) ?  <div id="contact-formError">{formError}</div> : <></>

                                }
                                {
                                    (isSuccess==true)? <div id="contact-formSuccess"><span>&#10003;</span>  The form has been sent. Thank you!</div> : <></>
                                }
                                <button id='contact-sendFormButton' onClick={() => form()}>Send</button>
                            </div>
                        </div>
                    </div>  
                </div>
                <Footer />
            </>
        );
}

export default Contact;