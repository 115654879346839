import React from "react";
import Header from "../../components_pl/Header";
import Footer from "../../components_pl/Footer";
import TitleSection from "../../components_en/TitleSection";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';

import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import UV_IconImg from '../../graphics/icons/UV_icon.gif';
import UVATubeImg from '../../graphics/products/UVA_tubes.jpg';
import ProductSlider from '../../components_en/ProductSlider'


function T810W () {
    return(
        <>
            <Header/>
            <TitleSection h1={'T8 10W'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div className='productCategories-productPageContainer'>
                    <div className='productCategories-descriptionAndParameters'>
                        <div className='productCategories-description'>
                            <ul className="productCategories-secondSectionList">
                            <li>Zaprojektowana i wyprodukowana w UE</li>
                            <li>Diody najwyższej jakości pochodzące z Korei</li>
                            <li>Trwałość 50 000 godzin</li>
                            <li>Wysoka skuteczność w łapaniu much potwierdzona badaniami</li>
                            <li>100% konstrukcji i produkcji w Polsce</li>
                            <li>Jednorodne światło bez zaciemnień (optyka z Niemiec)</li>
                            <li>Atrakcyjna cena</li>
                            <li>Gwarancja 2 lata</li>
                            </ul>
                        </div>
                        <ProductSlider productImages={[UVATubeImg]}/>
                    </div>
                    <div className='productCategories-productParameters'>
                            <div className='productCategories-productParametersBox'>
                                <p>Wymiary:</p>
                                <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                                <p>Średnica 25.8mm</p>
                                <p>L: 450mm</p>                                          
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                                <p>Napięcie znamionowe: 230V</p>
                                <p>Moc: 10W</p>
                                <p>Prąd: 50mA</p>
                                <p>IP20 (IEC 60529) </p>
                                <p>UVA: 3.7W</p>
                                <p>UVA LED: 370.5 nm</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                            <p>Temperatura pracy: -40°C - 45°C</p>
                                <p>Temperatura przechowywania: -45°C - 65°C</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={UV_IconImg} alt='icon'/>
                                <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                            </div>
                        </div>
                        </div>
        </div>
        <Footer/>
        </>
    );
}

export default T810W;